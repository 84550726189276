.main-features {
    box-shadow: 0px 3px 6px #00000014;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    transition: 0.3s all;
    z-index: 999;
    @apply shadow-lg;
}

.sticky + .content {
    padding-top: 60px;
}

html {
    scroll-behavior: smooth;
  }
