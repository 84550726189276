@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: muli;
        font-weight: 400;
        src: url(./fonts/Muli/Muli-Regular.woff) format('woff');
    }
    @font-face {
        font-family: muli;
        font-weight: 500;
        src: url(./fonts/Muli/Muli.woff) format('woff');
    }
    @font-face {
        font-family: muli;
        font-weight: 600;
        src: url(./fonts/Muli/Muli-Bold.woff) format('woff');
    }
    @font-face {
        font-family: muli;
        font-weight: 700;
        src: url(./fonts/Muli/Muli-SemiBold.woff) format('woff');
    }
    @font-face {
        font-family: verdana;
        src: url(./fonts/Verdana-Font/verdana.TTF) format('ttf');
    }
}

.bg-site-overlay {
    background: transparent linear-gradient(0deg, #0d1b3e 0%, #101e48b3 73%, #18255280 100%) 0% 0% no-repeat
        padding-box;
    opacity: 0.99;
}

.ga-subheading {
    @apply text-[40px] font-medium text-primary mb-0;
}

.ga-subtitle {
}

.ga-btn-secondary {
    @apply flex justify-center w-[365px] sm:w-[365px] h-[75px] sm:h-[75px]  items-center cursor-pointer font-bold transition text-primary hover:text-white border border-primary hover:bg-primary focus:outline-none text-center  rounded text-xl uppercase text-[22px] font-montserrat;
}

.teams-image {
    box-shadow: 0px 3px 8px #00000014;
}

.teams-slider {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.teams-slider-card {
    box-shadow: 0px 3px 8px #00000014;
    @apply rounded-2xl;
}

:root {
    --swiper-navigation-size: 24px !important;
    --swiper-theme-color: #2b528e !important;
}

.swiper-button-next,
.swiper-button-prev {
    border-radius: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000014;
    border: 0.30000001192092896px solid #3e6eb9;
    opacity: 1;

    &:after {
        font-weight: bold !important;
    }

    width: 40px !important;
    height: 40px !important;

    @media screen and (min-width: 768px) {
        width: 70px !important;
        height: 70px !important;
    }

    @apply transition-transform hover:-translate-y-2;
}

.image-slider {
    .swiper-wrapper {
        @apply items-center;
    }
}
